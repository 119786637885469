<template>
  <div class="doc-outter">
    <div class="doc-title">
      <h3>行李须知</h3>
    </div>
    <div class="doc-content">
      <div class="noteCont bage m_t_30">
        <div class="sec">
          <h4 class="b_h4">一、手提行李</h4>

          <p>国内航线免费随身携带物品规定：</p>
          <ul>
            <li>1、免费随身携带物品的重量以每件5公斤为限。</li>

            <li>
              2、持经济舱客票的旅客，每人只能随身携带一件物品；持头等舱客票的旅客，每人可随身携带的物品不得超过两件。
            </li>
            <li>
              3、每件随身携带物品的体积不得超过20cm*30cm*40cm（M60飞机）；20cm*40cm*55cm（波音飞机）。
            </li>
          </ul>
        </div>

        <div class="sec">
          <h4 class="b_h4">二、免费托运行李</h4>

          <p>中国大陆航线免费托运行李规定</p>

          <table border="1" class="m_t_20">
            <tbody>
              <tr>
                <td>机型</td>
                <td>航线范围</td>
                <td>客舱级别</td>
                <td>总重量上限</td>
                <td>每件尺寸总限</td>
              </tr>
              <tr>
                <td rowspan="2">MA60</td>
                <td rowspan="4">国内航线</td>
                <td>头等舱</td>
                <td>成人及儿童：30公斤；婴儿无免费行李额</td>
                <td rowspan="4">
                  体积均不得超过40cmX60cmX100cm。超过以上规定的行李，唯有事先征得幸福航空的同意，方可托运。
                </td>
              </tr>
              <tr>
                <td>经济舱</td>
                <td>成人及儿童：20公斤；婴儿无免费行李额</td>
              </tr>
              <tr>
                <td rowspan="2">波音</td>
                <td>头等舱</td>
                <td>成人及儿童：40公斤；婴儿无免费行李额</td>
              </tr>
              <tr>
                <td>经济舱</td>
                <td>成人及儿童：20公斤；婴儿无免费行李额</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="sec">
          <p class="txt_14_b">国内航线的逾重行李收费标准</p>
          <p>
            如果您的托运行李和自理行李合并计重，超过旅客免费行李额的部分，应当支付逾重行李费。逾重行李费率以每公斤按逾重行李票填开当日所使用的经济舱客全额票价1.5%计算，收费总金额以元为单位，尾数四舍五入。
          </p>
        </div>
        <div class="sec">
          <p class="txt_14_b">逾重行李费计算</p>
          <p>
            应收逾重行李费=（托运行李的重量-适用的免费行李额）×适用的逾重行李费率。
          </p>
        </div>

        <div class="sec">
          <h4 class="b_h4">三、行李延误损坏及赔偿</h4>
          <p class="txt_14_b">行李延误：</p>
          <p>
            若您到达目的地却发现托运行李延误抵达，请您第一时间前往机场行李查询处登记处理，幸福航空将会竭尽所能地给予协助。我们为行李处理不当深表抱歉，并且将尽最大努力为您寻找，以期将行李及时送还给您。
          </p>
          <p class="txt_14_b">行李追查：</p>
          <p>
            我们将会透过行李查询系统找寻您的行李，并会通知您追查进度。若需要任何协助，请与当地机场行李查询联系。
          </p>
          <p class="txt_14_b">行李运送：</p>
          <p>
            一旦确认您的行李位置后，我们将会立刻通知您，并会在行李抵达后送交于您指定的地址。
          </p>
          <p class="txt_14_b">行李损坏：</p>
          <p>
            如果无法当场修补，可送到市内专业的维修部进行修理、更换处理，或者根据行李维修所需的一般费用以现金形式赔付。若您还有其它疑问或需进一步协助，欢迎您与我们联络，我们将会为您效劳。
          </p>
          <p class="txt_14_b">行李赔偿：</p>
          <p>
            旅客的交运行李在幸福航空所属航班承运过程中，如发生遗失、损坏、污染、短缺应承担赔偿责任。行李赔偿可分为以下各类：临时生活费、丢失行李赔偿费、内物丢失赔偿费、破损（污染）行李赔偿费。
          </p>
          <p class="txt_14_b">
            赔偿依据—《中华人民共和国民用航空法》、华沙公约的使用规定中适用于行李赔偿的规定。
          </p>
        </div>

        <div class="sec">
          <h4 class="b_h4">四、行李运输的限制</h4>
          <p class="txt_14_b">不得作为行李运输的物品：</p>
          <p>
            1．下列物品不得作为行李或夹入行李（包括托运行李和自理行李）内托运，也不得随身携带入客舱：
          </p>
          危险品：如易燃易爆物品；氧化剂；毒性物质和传染性物质；放射性物质；腐蚀性物质；磁性物质；麻醉性、特殊刺激性或类似性质的物质；海鲜，容易污损飞机的物品等。
          <p></p>
          <p>
            2．枪支、弹药、仿真玩具枪、枪型打火机及其他类型具有攻击性的武器、器械。体育运动专用器械除外；
          </p>
          <p>3．军械、警械。</p>
          <p>
            4 .
            管制刀具：指匕首、刺刀、三棱刀（包括机械用的三棱刮刀）、带有自动装置的弹簧刀（跳刀）及其他相类似的单刃、双刃、三棱尖刀，但少数民族刀具除外。
          </p>
          <p>
            5．活体动物和具有刺激性异味或易引起其他旅客反感的水果，如：榴梿、椰子、菠萝蜜等。
          </p>
          <p>6．国家规定的禁运物品。</p>
          <p class="txt_14_b">不得作为托运行李运输的物品：</p>
          <p>
            需要专人照管的物品，不得作为托运行李或夹入行李内托运，而应作为自理行李或随身携带物品带入客舱运输。如：重要文件和资料；可转让票据；
            证券；货币；汇票；
            珠宝；数码相机；手机；笔记本电脑等个人电子设备；钥匙、贵重金属及其制品；银制品；贵重物品；古玩字画；易碎易损坏，海鲜等物品；易腐物品；样品；
            护照和其他身份证件、 旅行证件以及个人需定时服用的处方药等。
          </p>
          <p>
            承运人对托运行李内夹带上述物品的遗失、损坏或政府对任何物品的没收幸福航空不承担责任
          </p>
          <p>
            1．超过自理行李的重量、尺寸规定时，须按占座行李的标准为行李缴纳一定的费用。
          </p>
          <p>
            2．旅客携带大量的贵重物品（例如黄金）乘机，只能按照占座行李办理。办理手续时，旅客应出示有关管理部门开具的携带证明。
          </p>
          <p class="txt_14_b">限制运输的物品：</p>
          <p class="txt_12_b">
            下列物品只有在符合承运人运输条件的情况下，并经承运人同意，方可接受运输：
          </p>
          <p>
            1．精密仪器、电器如影碟机、录像机、电视机、音响器材等类物品，应作为货运托运，如按托运行李运输，必须妥善包装，并且此类物品的重量不得计算在免费行李额内。
          </p>
          <p>2．体育运动用器械，包括体育运动用枪支和弹药。</p>
          <p>
            3．家庭驯养的狗、猫、鸟或其他玩赏宠物、导盲犬和助听犬，不包括野生动物和具有怪异形体或可能伤人等特性的动物，如蛇等。
          </p>
          <p>4．外交信袋，机要文件。</p>
          <p>5．旅客旅途中使用的折叠轮椅、电动轮椅。</p>
          <p>
            6．管制刀具以外的利器、钝器，如菜刀、餐刀、水果刀、工艺品刀、手术刀、剪刀、铅笔刀，以及钢锉、铁锥、斧子、短棍、锤子等，应放入托运行李内运输；
          </p>
          <p>
            7．对旅客携带的一些不易被利用来劫机，对飞机安全威胁不大，又确系急用和属于正常使用的违禁物品，可由单位开具证明，准予随身携带。如护理重病患者外出就医的旅客，
            随身携带少量的酒精棉等医疗用品和器械，经征得机场或幸福航空同意，准其随身携带。
          </p>
          <p class="txt_14_b">托运行李的包装重量及体积的限制：</p>
          <p>
            1．
            托运行李必须包装完善、锁扣完好、捆扎牢固，能承受一定的压力，能够在正常的操作条件下安全装卸和运输，并应符合下列条件：
          </p>
          <p>(1) 旅行箱、旅行袋和手提包等必须加锁。</p>
          <p>(2) 两件以上的包件，不能捆为一件。</p>
          <p>(3) 行李上不能附插其他物品。</p>
          <p>(4) 竹篮、网兜、草绳、草袋等不能作为行李的外包装物。</p>
          <p>
            (5)
            带有危险品标记、标签的包装件一般不能作为行李的外包装物，如包装了行李，必须将该包装件上的危险品标记、标签去掉或覆盖。
          </p>
          <p>(6) 行李上应写明旅客的姓名、详细地址、电话号码。</p>
          <p>(7) 行李包装内不能用锯末、谷壳、草屑等作衬垫物。</p>
          <p>
            2．M60飞机及波音飞机托运行李单件体积均不得超过40cm×60cm×100cm。超过上述规定的行李，应事先征得幸福航空的同意方能托运。
          </p>
        </div>
        <div class="sec">
          <h4 class="b_h4">五、 幸福航空关于旅客携带锂电池登机的规定</h4>
          <p>
            <a
              href="http://www.joy-air.com/b2c/static/anounce/announcDetail.html?announceId=242"
              >请参照《关于旅客携带的锂电池及相关产品等危险品提示》的相关规定</a
            >
          </p>
        </div>

        <div class="sec">
          <h4 class="b_h4">联系方式：</h4>
          <p>1. 客服中心旅客服务热线：400-868-0000</p>
          <p>2. 幸福航空官方订票网址：www.joy-air.com</p>
          <p>3. 客服中心旅客服务邮箱：jrkefu@joy-air.net</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
</style>
